<template>
  <vx-card  style="padding-left: 10px">

    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="open_add_subject_popup"
          icon-pack="feather"
          icon="icon-edit"
        >Add Subject</vs-button>
      </div>
    </div>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table
      ref="table"
      :sst="true"
      :total="totalDocs"
      :data="users"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
      :max-items="dataTableParams.limit"
      :noDataText="noDataText"
      search
    >
      <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between" >
        <div style='float: left'>
          <vs-select placeholder="10" vs-multiple autocomplete v-model="dataTableParams.limit" class="mr-6">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in limitOptions" :clearable="false"/>
          </vs-select>
        </div>
      </div>

      <template slot="thead">
            <vs-th sort-key="name">Name</vs-th>
           <vs-th sort-key="createdAt">Created At</vs-th>
        <vs-th sort-key="action">Action</vs-th>
      </template>

      <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">
                    {{ data[indextr].name}} <b v-if="tr.children.length>0">({{tr.children.length}})</b>
                </vs-td>
              <vs-td :data="data[indextr].createdAt">{{ data[indextr].createdAt | date_formatter }}</vs-td>

              <vs-td :data="data[indextr]._id">
                  <p >
                    <vx-tooltip text="Add Course Type"  position="left" >
                    <vs-icon class="mr-2" icon-pack="feather" icon="icon-plus" size="small" @click="editDetailHandler(data[indextr],'addChild')"></vs-icon>
                    </vx-tooltip>
                    <vx-tooltip text="Edit Subject" position="left" >
                      <vs-icon class="mr-2" icon-pack="feather" icon="icon-edit" size="small" @click="editDetailHandler(data[indextr])"></vs-icon>
                    </vx-tooltip>
                    <vx-tooltip text="Delete Subject" position="left" >
                    <vs-icon class="mr-2" icon-pack="feather" icon="icon-trash" size="small" @click="openDeleteConfirm(data[indextr])"></vs-icon>
                    </vx-tooltip>
                  </p>
                  </vs-td>

          <template class="expand-user" slot="expand">
              <vs-list>
                <vs-list-item v-for="child in tr.children" :key="child" :title="child.name">
                    <vs-icon class="ml-2" icon-pack="feather" icon="icon-edit" size="sm" @click="editDetailHandler(child)"></vs-icon>
                    <vs-icon class="ml-2" icon-pack="feather" icon="icon-trash" size="sm" @click="openChildDeleteConfirm(child._id)"></vs-icon>
                  </vs-list-item>
              </vs-list>
          </template>
            </vs-tr>
        </template>
    </vs-table>
    <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
      <div class="pagination-div" v-if="serverResponded">
        <paginate
          :page-count="totalPage"
          :click-handler="handleChangePage"
          class="pagination"
          :page-range="9"
          :prevText="'<'"
          :nextText="'>'"
        ></paginate>
      </div>
    </div>
  </div>

 <vs-popup classContent="popup-example"  title="Add Subject" :active.sync="popupActivo0">
    <vs-input
                        icon-no-border
                        icon-after
                        icon-pack="feather"
                        icon="icon-check-circle" class="w-full mt-4" label="Subject Name" v-model="parent.name" v-validate="'required'" data-vv-as="subject name" data-vv-validate-on="blur" name="subjectName" />
    <span class="text-danger text-sm"  v-show="errors.has('subjectName')"><p>{{ errors.first('subjectName') }}</p></span>
<!-- <div v-for="(child, index) in childrenCategory" :key="child">
    <vs-input class="w-full mt-4" label="Title" v-model="child.name" v-validate="'required'" data-vv-as="title" data-vv-validate-on="blur" name="title" />
    <span class="text-danger text-sm"  v-show="errors.has('title'+index)">{{ errors.first('title') }}</span>
</div> -->

<vs-button color="success" class="ml-auto mt-4" @click="addNewSubject"> Add Subject</vs-button>

</vs-popup>
<!-- edit subject popup -->
 <vs-popup classContent="popup-example"  title="Edit Subject" :active.sync="popupActivo1">
    <vs-input
          class="w-full mt-4" label="Subject Name" v-model="parent.name" v-validate="'required'" data-vv-as="subject name" data-vv-validate-on="blur" name="subjectNamer" />
    <span class="text-danger text-sm"  v-show="errors.has('subjectNamer')">{{ errors.first('subjectNamer') }}</span>
    <vs-button color="success" class="ml-auto mt-4" @click="updateSubject"> Update Subject</vs-button>
</vs-popup>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
import { mapActions } from 'vuex';
import moment from "moment";

export default {
  components: {
    'v-select': vSelect,
  },
  data() {
    return {
      popupActivo0: false,
      popupActivo1: false,
      isMounted: false,
      noDataText:'No subjects available..',
      totalDocs: 0,
      currentPage: 1,
      parent: {_id: '',name: '', parentId:''},
      childrenLanguage:[],
      childrenCategory:[
        {
          name: '',
          parentId: ''
        }
      ],
      dataTableParams: {
        searchKey: "",
        sort: "name",
        dir: "desc",
        page: 1,
        limit: 25
      },
      users: [],
      serverResponded: false,
      limitOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
    };
  },
  methods: {
    ...mapActions('subject', ['fetchSubjects', 'addSubjects','removeSubjects','removeChildrenSubject','updateSubjectById']),
    moment(date){
      return moment(date)
    },
    updateSubject(){
      let subjectId = this.parent._id
      let name = this.parent.name
      let parentId = this.parent.parentId
      this.updateSubjectById({subjectId, name, parentId}).then(res => {
        this.$vs.notify({
          title: 'Update',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      this.popupActivo1 = false
      this.getSubjectList();
      }).catch(err => {
            let errorMessage = 'Something went wrong.'
            if(err && err.response && err.response.data){
              errorMessage = err.response.data.message
            }
        this.$vs.notify({
          title: 'Failure',
          text: errorMessage,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      this.popupActivo1 = false
      })
    },
    addNewSubject(){
      let parent = this.parent
      let childrenCategory = this.childrenCategory
      this.addSubjects({parent, childrenCategory}).then(res=>{
        this.$vs.notify({
          title: 'Update',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      this.popupActivo0 = false
      this.getSubjectList();
      }).catch(err => {
        this.popupActivo0 = false
            let errorMessage = 'Something went wrong.'
            if(err && err.response && err.response.data){
              errorMessage = err.response.data.message
            }
          this.$vs.notify({
            title: 'Failure',
            text: errorMessage,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    addNewChildSubject(){

      this.childrenCategory.push({name:'', parentId:''})
    },
    getSubjectList() {
      let self = this;
      this.fetchSubjects(self.dataTableParams).then(res => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.serverResponded = true;
      })

    },
    handleSearch(searching) {
      this.serverResponded = false;
      this.dataTableParams.searchKey = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSubjectList();
    },
     handleChangePage(page) {
      this.dataTableParams.page = page;
       this.getSubjectList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.currentPage = 1;
      this.$refs.table.currentx = 1;
      this.getSubjectList();
    },
    createTutorHandler(){
      this.$router.push({ name: 'AddTutor'})
    },
    viewDetailHandler(id) {
      this.$router.push({ name: 'TutorDetails', params: { tutorId: id } })
    },
    editDetailHandler(tr, key="editSubject") {
      this.popupActivo1 = true
      if(key=="addChild"){
        this.parent = {
          _id: "",
          name: "",
          parentId: tr._id
        }
      }
      else{
        this.parent = {
          _id: tr._id,
          name: tr.name
        }
      }
    },
    openDeleteConfirm(subjectData){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Delete`,
        text: `You are about to delete this subject.`,
        accept: this.deleteDetailHandler,
        acceptText: "Delete",
        parameters: subjectData
      })
    },
    deleteDetailHandler(subjectData) {
      let childrenIds = []
      let subjectId = subjectData._id
      if(subjectData.children.length > 0){
        childrenIds = subjectData.children.map(function(child){ return child._id })
      }
      this.removeSubjects({subjectId, childrenIds}).then(res => {
        this.$vs.notify({
          title: 'Update',
          text: 'Subject deleted successfully',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      this.serverResponded = false;
      this.dataTableParams.page = 1;

     this.getSubjectList();

      }).catch(err => {
        let errorMessage = 'Something went wrong.'
        if(err && err.response && err.response.data){
          errorMessage = err.response.data.message
        }
        this.$vs.notify({
          title: 'Failure',
          text: errorMessage,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },

    openChildDeleteConfirm(childrenId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Delete`,
        text: `You are about to delete this subject.`,
        accept: this.deleteChildDetailHandler,
        acceptText: "Delete",
        parameters: childrenId
      })
    },
    deleteChildDetailHandler(childrenId) {
      this.removeChildrenSubject({childrenId}).then(res => {
        this.$vs.notify({
          title: 'Update',
          text: 'Subject deleted successfully',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      this.getSubjectList();

      }).catch(err => {
        let errorMessage = 'Something went wrong.'
        if(err && err.response && err.response.data){
          errorMessage = err.response.data.message
        }
        this.$vs.notify({
          title: 'Failure',
          text: errorMessage,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    open_add_subject_popup(){
      this.popupActivo0 = true
      this.parent= {_id: '',name: ''}
      this.childrenCategory =[
        {
          name: '',
          parentId: ''
        }
      ]
    }
  },
  watch: {
  	'dataTableParams.page': function(newVal, oldVal) {
      if(newVal !== oldVal){
        this.dataTableParams.page = newVal;
        this.getSubjectList();
      }
    },
    'dataTableParams.limit': function(newlimit, oldLimit) {
      if(newlimit !== oldLimit){
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getSubjectList()
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
      parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getSubjectList();
  }
};
</script>
<style>
.actionButtons{
  display: inline-block;
}
.actionButtons .actionButton{
  float: left;
}
.vs-list--slot {
  size: 50%;
  margin-left: 5px !important;
}
</style>
